<!-- 手动绑定房产 -->

<template>
  <div class="manually-bind-dialog">
    <w-dialog ref="dialogRef" title="手动绑定房产" width="60%" top="10vh" :confirmText="'确认绑定'" @wConfirm="wConfirm">
      <div class="manually-bind-content">
        <w-title :title_name="'会员信息'"></w-title>
        <el-row :gutter="20" class="member-info">
          <el-col :span="8">
            <div class="flex">
              <div class="member-info-title">会员姓名</div>
              <div class="member-info-content">{{ currentRow.m_realname }}</div>
            </div>
          </el-col>
          <el-col :span="8">
            <div class="flex">
              <div class="member-info-title">会员账号</div>
              <div class="member-info-content">{{ currentRow.m_mobile }}</div>
            </div>
          </el-col>
          <el-col :span="8">
            <div class="flex">
              <div class="member-info-title">会员积分</div>
              <div class="member-info-content">{{ currentRow.m_integration }}</div>
            </div>
          </el-col>
          <el-col :span="8">
            <div class="flex s-m-t">
              <div class="member-info-title">会员性别</div>
              <div class="member-info-content">
                {{ currentRow.m_sex == '1' ? '男' : currentRow.m_sex == '2' ? '女' : '未知' }}
              </div>
            </div>
          </el-col>
          <el-col :span="8">
            <div class="flex s-m-t">
              <div class="member-info-title">会员生日</div>
              <div class="member-info-content">{{ currentRow.m_birth }}</div>
            </div>
          </el-col>
          <el-col :span="8">
            <div class="flex s-m-t">
              <div class="member-info-title">会员等级</div>
              <div class="member-info-content">{{ currentRow.m_level_label }}</div>
            </div>
          </el-col>
        </el-row>

        <div class="xl-m-t">
          <w-title :title_name="'会员房产'"></w-title>
          <el-form ref="formRef" :model="ruleForm" :rules="rules" labelPosition="top">
            <el-row :gutter="20">
              <el-col :span="8">
                <el-form-item label="绑定项目" prop="pid">
                  <el-select v-model="ruleForm.pid" placeholder="请选择" clearable @change="projectChange">
                    <el-option v-for="(item, i) in projectOptions" :key="i" :label="item.name"
                      :value="item.id"></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="绑定楼栋" prop="bid">
                  <el-select v-model="ruleForm.bid" placeholder="请选择" clearable filterable @change="buildingChange">
                    <el-option v-for="(item, i) in buildingOptions" :key="i" :label="item.name"
                      :value="item.id"></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="绑定房号" prop="rid">
                  <el-select v-model="ruleForm.rid" placeholder="请选择" clearable filterable>
                    <el-option v-for="(item, i) in roomOptions" :key="i" :label="item.roomnum"
                      :value="item.id"></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>
        </div>
      </div>
    </w-dialog>
  </div>
</template>

<script>
import { ref, reactive, } from "vue";
import { VipApi, HotelApi, DinnerApi, ScenicApi, StoreApi, } from "@/plugins/api.js";
import { ElMessage, } from "element-plus";

export default {
  emits: ["submit"],
  setup(props, { emit }) {
    const dialogRef = ref(null);  // 弹框对象
    const currentRow = ref('');  // 当前行数据
    const projectOptions = ref([]);  // 项目选项
    const buildingOptions = ref([]);  // 楼栋选项
    const roomOptions = ref([]);  // 房号选项
    const bindingType = ref('');  // 绑定类型
    const formRef = ref(null);  // 表格对象
    const apiUrl = ref('');
    const ruleForm = ref({
      pid: '',  // 项目id
      bid: '',  // 楼栋id
      rid: '',  // 房屋id
    });
    const rules = reactive({
      pid: [
        { required: true, message: "请选择项目", trigger: "change", },
      ],
      bid: [
        { required: true, message: "请选择楼栋", trigger: "change", },
      ],
      rid: [
        { required: true, message: "请选择房号", trigger: "change", },
      ],
    });

    /**
     * 
     * 打开对话框
     * 
     * **/
    const openDialog = (data, type) => {
      if (type == 'member') {
        // 会员
        apiUrl.value = VipApi;
      } else if (type == 'hotel') {
        // 酒店
        apiUrl.value = HotelApi;
      } else if (type == 'dining') {
        // 餐厅
        apiUrl.value = DinnerApi;
      } else if (type == 'scenic') {
        // 景区
        apiUrl.value = ScenicApi;
      } else if (type == 'store') {
        // 门店
        apiUrl.value = StoreApi;
      }
      bindingType.value = type;
      currentRow.value = data;
      ruleForm.value = {
        pid: '',  // 项目id
        bid: '',  // 楼栋id
        rid: '',  // 房屋id
      };
      buildingOptions.value = [];
      roomOptions.value = [];
      getRoomsProject();
      dialogRef.value.show();
    }
    /**
     * 
     * 关闭对话框
     * 
     * **/
    const closeDialog = () => {
      dialogRef.value.close();
    }
    /**
     * 
     * 关闭对话框加载状态
     * 
     * **/
    const closeDialogLoading = () => {
      dialogRef.value.isLoading = false;
    }
    /**
     * 
     * 获取项目选项
     * 
     * **/
    const getRoomsProject = () => {
      apiUrl.value.getRoomsProject().then((res) => {
        if (res.Code === 200) {
          projectOptions.value = res.Data ? res.Data : [];
        } else {
          ElMessage.error(res.Message);
        }
      });
    }
    /**
     * 
     * 获取楼栋选项
     * 
     * **/
    const getBuilding = () => {
      apiUrl.value.getBuilding({ pid: ruleForm.value.pid }).then((res) => {
        if (res.Code === 200) {
          buildingOptions.value = res.Data ? res.Data : [];
        } else {
          ElMessage.error(res.Message);
        }
      });
    }
    /**
     * 
     * 获取房号选项
     * 
     * **/
    const getRooms = () => {
      apiUrl.value.getRooms({ bid: ruleForm.value.bid }).then((res) => {
        if (res.Code === 200) {
          roomOptions.value = res.Data ? res.Data : [];
        } else {
          ElMessage.error(res.Message);
        }
      });
    }
    /**
     * 
     * 获取房号选项
     * 
     * **/
    const projectChange = () => {
      ruleForm.value.bid = '';
      ruleForm.value.rid = '';
      roomOptions.value = [];
      if (!ruleForm.value.pid) {
        buildingOptions.value = [];
        return false;
      } else {
        getBuilding();
      }
    }
    const buildingChange = () => {
      ruleForm.value.rid = '';
      if (!ruleForm.value.bid) {
        roomOptions.value = [];
        return false;
      } else {
        getRooms();
      }
    }
    /**
     * 
     * 确定按钮
     * 
     * **/
    const wConfirm = () => {
      formRef.value.validate((valid) => {
        if (valid) {
          let params = {
            m_id: currentRow.value.m_id,
            pid: ruleForm.value.pid,
            bid: ruleForm.value.bid,
            rid: ruleForm.value.rid,
          };
          dialogRef.value.isLoading = true;
          emit('submit', params);
        }
      })
    }

    return {
      openDialog,
      dialogRef,
      closeDialog,
      closeDialogLoading,
      currentRow,
      wConfirm,
      formRef,
      ruleForm,
      rules,
      projectOptions,
      buildingOptions,
      roomOptions,
      getRoomsProject,
      getBuilding,
      getRooms,
      projectChange,
      buildingChange,
      bindingType,
      apiUrl,
    };
  },
};
</script>

<style lang="scss">
.manually-bind-dialog {
  .manually-bind-content {
    margin-bottom: 100px;
  }

  .member-info-title {
    color: #92979E;
    text-align: left;
    width: 80px;
  }

  .member-info-content {
    color: #262C30;
  }
}
</style>