<!-- 会员详情 -->

<template>
  <div class="member-details-dialog">
    <w-dialog ref="dialogRef" title="会员详情" width="70%" btnWidth="140px" top="5vh" :hideFooter="true">
      <w-title :title_name="'会员信息'"></w-title>
      <el-row :gutter="20" class="member-info">
        <el-col :span="8">
          <div class="flex">
            <div class="member-info-title">会员姓名</div>
            <div class="member-info-content">{{ infoDatas.m_realname }}</div>
          </div>
        </el-col>
        <el-col :span="8">
          <div class="flex">
            <div class="member-info-title">会员账号</div>
            <div class="member-info-content">{{ infoDatas.m_mobile }}</div>
          </div>
        </el-col>
        <el-col :span="8">
          <div class="flex">
            <div class="member-info-title">会员积分</div>
            <div class="member-info-content">{{ infoDatas.m_integration }}</div>
          </div>
        </el-col>
        <el-col :span="8">
          <div class="flex s-m-t">
            <div class="member-info-title">会员性别</div>
            <div class="member-info-content">{{ infoDatas.m_sex == '1' ? '男' : infoDatas.m_sex == '2' ? '女' : '未知' }}
            </div>
          </div>
        </el-col>
        <el-col :span="8">
          <div class="flex s-m-t">
            <div class="member-info-title">会员生日</div>
            <div class="member-info-content">{{ infoDatas.m_birth }}</div>
          </div>
        </el-col>
        <el-col :span="8">
          <div class="flex s-m-t">
            <div class="member-info-title">会员等级</div>
            <div class="member-info-content">{{ infoDatas.m_level_label }}</div>
          </div>
        </el-col>
      </el-row>

      <div class="s-m-t">
        <el-tabs v-model="activeName" class="demo-tabs" @tab-click="handleClick">
          <el-tab-pane label="全部订单" name="2"></el-tab-pane>
          <el-tab-pane label="酒店订单" name="3"></el-tab-pane>
          <el-tab-pane label="门店订单" name="4"></el-tab-pane>
          <el-tab-pane label="餐饮订单" name="5"></el-tab-pane>
          <el-tab-pane label="景区订单" name="6"></el-tab-pane>
          <el-tab-pane label="积分明细" name="7"></el-tab-pane>
        </el-tabs>

        <div class="flex details-type align-center" v-if="activeName == 7">
          <div>明细类型：</div>
          <div class="flex">
            <div v-for="(item, index) in typeLabels" :key="index" class="details-type-title"
              :class="typeActive == index ? 'details-type-active' : ''" @click="onClickLabel(index)">{{ item.name }}
            </div>
          </div>
        </div>

        <div class="s-m-t">
          <common-table ref="tableRef" tableHeight="350" :apiName="interfaceUrl.memberDetails"
            :extraParame="extraParame" :columns="tableColumns">
            <template #mi_type_label="{ row }">
              <div class="integral-type" :class="row.m_owner == '收入' ? 'btn-black' : 'btn-yellow'">
                {{ row.mi_type_label }}</div>
            </template>

            <template #amount v-if="activeName == 7">
              <div class="s-m-t points-surplus">
                <span>积分结余：</span>
                <span class="points-surplus-num">{{ statics }}</span>
              </div>
            </template>
          </common-table>
        </div>
      </div>
    </w-dialog>
  </div>
</template>

<script>
  import { ref, reactive, onMounted, nextTick, } from "vue";
  import { ElMessage } from "element-plus";
  import { VipApi, HotelApi, } from "@/plugins/api.js";
  export default {
    emits: ["submit",],
    setup(props, { emit }) {
      const dialogRef = ref(null);  // 对话框
      const currentRow = ref('');  // 当前会员数据
      const infoDatas = ref('');  // 详情数据
      const activeName = ref('2');  // 标签页下标   1：会员详情；2：全部订单；3：酒店订单；4：门店订单；5：餐饮订单；6：景区订单；7：积分明细
      const typeActive = ref(0);  // 明细类型下标
      const typeLabels = ref([
        { id: 1, name: '全部' },
        { id: 2, name: '收入' },
        { id: 3, name: '支出' },
      ]);  // 明细类型标签数据
      const tableRef = ref(null);  // 表格对象
      const extraParame = ref({  // 表格自定义参数
        m_id: '',  // 会员id
        action: '',  // 订单类型   1：会员详情；2：全部订单；3：酒店订单；4：门店订单；5：餐饮订单；6：景区订单；7：积分明细
        mi_type: '',  // 1：收入；2：支出
      })
      const orderTable = ref([  // 订单表格配置
        {
          prop: "or_type_label",
          label: "订单类型",
        },
        {
          prop: "or_sn",
          label: "订单编号",
        },
        {
          prop: "or_money",
          label: "消费金额",
        },
        {
          prop: "or_yhmoney",
          label: "优惠金额",
        },
        {
          prop: "created_at",
          label: "消费时间",
        },
      ]);
      const integralTable = ref([  // 积分表格配置
        {
          prop: "mi_integration",
          label: "积分",
        },
        {
          prop: "mi_type_label",
          type: "customRender",
          label: "类型",
        },
        {
          prop: "mi_source_label",
          label: "来源",
        },
        {
          prop: "mi_main_label",
          label: "商家",
        },
        {
          prop: "mi_source_type_label",
          label: "商家类型",
        },
        {
          prop: "mi_adder",
          label: "操作人",
        },
        {
          prop: "created_at",
          label: "操作时间",
        },
      ]);
      const tableColumns = ref([]);  // 表格配置数据
      const statics = ref(0);  // 积分结余
      const interfaceUrl = ref('');  // 接口
      /**
       * 
       * 打开对话框
       * 
       * **/
      const openDialog = (row, url) => {
        interfaceUrl.value = url;
        currentRow.value = row;
        extraParame.value.m_id = row.m_id;
        extraParame.value.action = 2;
        extraParame.value.mi_type = '';
        activeName.value = '2';
        tableColumns.value = orderTable.value;
        getMemberDetails(1);
        dialogRef.value.show();
      }
      /**
       * 
       * 关闭对话框
       * 
       * **/
      const closeDialog = () => {
        dialogRef.value.close();
      }
      /**
       * 
       * 关闭加载状态
       * 
       * **/
      const closeDialogLoading = () => {
        dialogRef.value.isLoading = false;
      }
      /**
       * 
       * 获取详情数据
       * 
       * **/
      const getMemberDetails = (type) => {
        interfaceUrl.value.memberDetails({ m_id: currentRow.value.m_id, action: type, mi_type: extraParame.value.mi_type, }).then((res) => {
          if (res.Code === 200) {
            if (type == 1) {
              // 会员详情
              infoDatas.value = res.Data.member;
            }
            if (type == 7) {
              // 积分明细
              statics.value = res.Data.statics;
            }
            nextTick(() => {
              tableRef.value.tableLoad();
            })
          } else {
            ElMessage.error(res.Message);
          }
        });
      }
      /**
       * 
       * 切换标签页
       * 
       * **/
      const handleClick = () => {
        extraParame.value.action = activeName.value;
        extraParame.value.mi_type = '';
        if (activeName.value == 7) {
          // 积分明细
          tableColumns.value = integralTable.value;
          typeActive.value = 0;
          getMemberDetails(activeName.value);
        } else {
          // 订单
          tableColumns.value = orderTable.value;
        }
        tableRef.value.tableLoad();
      }
      /**
       * 
       * 切换明细类型标签
       * 
       * **/
      const onClickLabel = (i) => {
        typeActive.value = i;
        extraParame.value.mi_type = i == 0 ? '' : i;
        tableRef.value.tableLoad();
        getMemberDetails(activeName.value);
      }
      onMounted(() => { });

      return {
        openDialog,
        VipApi,
        dialogRef,
        closeDialog,
        closeDialogLoading,
        getMemberDetails,
        activeName,
        handleClick,
        typeLabels,
        typeActive,
        onClickLabel,
        tableRef,
        tableColumns,
        infoDatas,
        orderTable,
        integralTable,
        extraParame,
        statics,
        interfaceUrl,
      };
    },
  };
</script>

<style lang="scss">
  .member-details-dialog {
    .member-info {}

    .member-info-title {
      color: #92979E;
      text-align: left;
      width: 80px;
    }

    .member-info-content {
      color: #262C30;
    }

    .el-tabs .el-tabs__item.is-active {
      color: #262C30;
      font-size: 16px;
      font-weight: bold;
    }

    .details-type {
      background-color: #F3F4F8;
      padding: 15px;
      color: #92979E;
    }

    .details-type-title {
      background-color: var(--text-white-color);
      width: 72px;
      text-align: center;
      color: #5D6066;
      margin-right: 10px;
      height: 28px;
      line-height: 28px;
    }

    .details-type-active {
      background-color: var(--theme-color);
      color: var(--text-white-color);
    }

    .points-surplus {
      text-align: end;
      margin-right: 20px;
      font-weight: bold;
    }

    .points-surplus-num {
      color: #0072F6;
    }

    .integral-type {
      width: 54px;
      height: 24px;
      border-radius: 4px;
      text-align: center;
    }
  }
</style>